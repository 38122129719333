import type { RouteLocationNormalized } from 'vue-router';

export const extractRouteMeta = (
  route: RouteLocationNormalized,
  key: string,
  defaultValue: any,
) => {
  if (Object.hasOwn(route.meta, key)) {
    return route.meta[key];
  }
  return defaultValue;
};

export const extractRouteAuthMeta = (
  route: RouteLocationNormalized,
): boolean | 'guest' => {
  return extractRouteMeta(route, 'auth', true);
};

export const normalizePath = (path: string = '') => {
  // クエリパラメータを除去
  let result = path.split('?')[0];

  // 末尾のスラッシュを削除
  if (result.charAt(result.length - 1) === '/') {
    result = result.slice(0, -1);
  }

  return result;
};
