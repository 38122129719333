import * as Sentry from '@sentry/vue';
import type { RuntimeConfig } from 'nuxt/schema';

const graphqlDefaultClientHost = (config: RuntimeConfig) => {
  const defaultClient = config.public['graphql-client']?.clients?.default;
  if (!defaultClient) {
    return 'localhost';
  }
  if (typeof defaultClient === 'string') {
    return defaultClient;
  }
  return defaultClient.host;
};

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter();
  const config = useRuntimeConfig();

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    debug: config.public.sentryEnableDebug || false, // Enable debug mode
    environment: config.public.environment || 'local', // Set environment
    // Performance Monitoring
    tracesSampleRate: Number(config.public.sentryTracesSampleRate) || 1.0, // Capture 100% of the transactions, reduce in production!
    tracePropagationTargets: ['localhost', graphqlDefaultClientHost(config)],
    // Session Replay
    replaysSessionSampleRate:
      Number(config.public.sentryReplaysSessionSampleRate) || 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate:
      Number(config.public.sentryReplaysOnErrorSampleRate) || 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    // The following enables exceptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        // eslint-disable-next-line no-console
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        );
      }
      // Continue sending to Sentry
      return event;
    },
  });
});
