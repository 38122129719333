export default defineNuxtPlugin(() => {
  useGqlError((err: any) => {
    // Only log during development
    if (process.env.NODE_ENV !== 'production') {
      for (const gqlError of err.gqlErrors) {
        // eslint-disable-next-line no-console
        console.error('[nuxt-graphql-client] [GraphQL error]', {
          client: err.client,
          statusCode: err.statusCode,
          operationType: err.operationType,
          operationName: err.operationName,
          gqlError,
        });
      }
    }

    const tokenExpired = err.gqlErrors.some(
      (e: any) =>
        e.message.includes('id-token-expired') ||
        e.message.includes('You do not have permission to perform this action'),
    );
    const tokenRevoked = err.gqlErrors.some((e: any) =>
      e.message.includes('id-token-revoked'),
    );
    const unauthorized = err.gqlErrors.some(
      (e: any) =>
        e.message.includes('invalid-claims') ||
        e.message.includes('insufficient-permission'),
    );

    if (tokenExpired || tokenRevoked || unauthorized) {
      // eslint-disable-next-line no-console
      console.error(
        '[nuxt-graphql-client] [GraphQL error] Token expired. Logging out...',
      );
      useGqlToken(null);
      clearNuxtData();

      const router = useRouter();
      if (router) {
        if (router.currentRoute.value.path !== '/accounts/login') {
          navigateTo({
            name: 'accounts-login',
            query: {
              next: router.currentRoute.value.fullPath,
            },
          });
        }
      }
    }
  });
});
