<script setup>
const props = defineProps({
  throttle: {
    type: Number,
    default: 200,
  },
  duration: {
    type: Number,
    default: 2000,
  },
});

const progress = ref(0);
const isLoading = ref(false);
const step = computed(() => 10000 / props.duration);

let _timer = null;
let _throttle = null;

// methods
const start = () => {
  clear();
  progress.value = 0;
  if (props.throttle && process.client) {
    _throttle = setTimeout(() => {
      isLoading.value = true;
      _startTimer();
    }, props.throttle);
  } else {
    isLoading.value = true;
    _startTimer();
  }
};
const finish = () => {
  progress.value = 100;
  _hide();
};

const clear = () => {
  clearInterval(_timer);
  clearTimeout(_throttle);
  _timer = null;
  _throttle = null;
};

const _increase = (num) => {
  progress.value = Math.min(100, progress.value + num);
};

const _hide = () => {
  if (process.client) {
    setTimeout(() => {
      isLoading.value = false;
      setTimeout(() => {
        progress.value = 0;
      }, 400);
    }, 500);
  }
};

const _startTimer = () => {
  if (process.client) {
    _timer = setInterval(() => {
      _increase(step.value);
    }, 100);
  }
};

// Hook to app lifecycle
const nuxtApp = useNuxtApp();
nuxtApp.hook('page:start', start);
nuxtApp.hook('page:finish', finish);
nuxtApp.hook('vue:error', finish);

onBeforeUnmount(clear);
</script>

<template>
  <div
    v-if="isLoading"
    class="fixed inset-0 z-50 flex h-screen w-full flex-col items-center justify-center overflow-hidden bg-gray-700 opacity-75"
  >
    <svg
      class="animate-spin"
      width="200px"
      height="200px"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        stroke="#8cd0e5"
        stroke-width="10"
        r="35"
        stroke-dasharray="164.93361431346415 56.97787143782138"
        transform="matrix(1,0,0,1,0,0)"
        style="transform: matrix(1, 0, 0, 1, 0, 0)"
      ></circle>
    </svg>
  </div>
</template>
