import { default as callbackGQHDreTHdnMeta } from "/opt/buildhome/repo/pages/accounts/callback.vue?macro=true";
import { default as _91key_93Zwj3om9vtfMeta } from "/opt/buildhome/repo/pages/accounts/confirm-email/[key].vue?macro=true";
import { default as loginBNM8gEDHhUMeta } from "/opt/buildhome/repo/pages/accounts/login.vue?macro=true";
import { default as logoutQ1m7I4wPAOMeta } from "/opt/buildhome/repo/pages/accounts/logout.vue?macro=true";
import { default as doneqMeIrXurHHMeta } from "/opt/buildhome/repo/pages/accounts/password/reset/done.vue?macro=true";
import { default as forceDcDou8ot0JMeta } from "/opt/buildhome/repo/pages/accounts/password/reset/force.vue?macro=true";
import { default as indexkuJMYJoIBAMeta } from "/opt/buildhome/repo/pages/accounts/password/reset/index.vue?macro=true";
import { default as _91key_93KyNTDB81c4Meta } from "/opt/buildhome/repo/pages/accounts/password/reset/key/[key].vue?macro=true";
import { default as doneJ3YrVyF9UQMeta } from "/opt/buildhome/repo/pages/accounts/password/reset/key/done.vue?macro=true";
import { default as agentskGXKCGqMWoMeta } from "/opt/buildhome/repo/pages/accounts/profile/agents.vue?macro=true";
import { default as index87WCLm4BcNMeta } from "/opt/buildhome/repo/pages/accounts/profile/index.vue?macro=true";
import { default as signupjOBeTBccSrMeta } from "/opt/buildhome/repo/pages/accounts/signup.vue?macro=true";
import { default as faq6z6qNnXVtzMeta } from "/opt/buildhome/repo/pages/faq.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as _91cartItemId_93WDMkW6QmoJMeta } from "/opt/buildhome/repo/pages/orders/checkout/[cartItemId].vue?macro=true";
import { default as indexMAffu2oHInMeta } from "/opt/buildhome/repo/pages/orders/checkout/index.vue?macro=true";
import { default as estimate_45confirmAbqfzRBsTJMeta } from "/opt/buildhome/repo/pages/orders/estimate-confirm.vue?macro=true";
import { default as _91orderGroupId_93RWdXJrhbqyMeta } from "/opt/buildhome/repo/pages/orders/history/[orderGroupId].vue?macro=true";
import { default as indexlsbnflpvOFMeta } from "/opt/buildhome/repo/pages/orders/history/index.vue?macro=true";
import { default as _91orderGroupId_93DUv8FEsdJuMeta } from "/opt/buildhome/repo/pages/orders/order-confirm/[orderGroupId].vue?macro=true";
import { default as indexRWWTRn8HzHMeta } from "/opt/buildhome/repo/pages/orders/order-confirm/index.vue?macro=true";
import { default as _91skuId_93pC0c9HHvXyMeta } from "/opt/buildhome/repo/pages/products/[skuId].vue?macro=true";
import { default as search9SBE2lFXzHMeta } from "/opt/buildhome/repo/pages/products/search.vue?macro=true";
export default [
  {
    name: "accounts-callback",
    path: "/accounts/callback",
    meta: callbackGQHDreTHdnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/callback.vue").then(m => m.default || m)
  },
  {
    name: "accounts-confirm-email-key",
    path: "/accounts/confirm-email/:key()",
    meta: _91key_93Zwj3om9vtfMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/confirm-email/[key].vue").then(m => m.default || m)
  },
  {
    name: "accounts-login",
    path: "/accounts/login",
    meta: loginBNM8gEDHhUMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/login.vue").then(m => m.default || m)
  },
  {
    name: "accounts-logout",
    path: "/accounts/logout",
    meta: logoutQ1m7I4wPAOMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/logout.vue").then(m => m.default || m)
  },
  {
    name: "accounts-password-reset-done",
    path: "/accounts/password/reset/done",
    meta: doneqMeIrXurHHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/password/reset/done.vue").then(m => m.default || m)
  },
  {
    name: "accounts-password-reset-force",
    path: "/accounts/password/reset/force",
    meta: forceDcDou8ot0JMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/password/reset/force.vue").then(m => m.default || m)
  },
  {
    name: "accounts-password-reset",
    path: "/accounts/password/reset",
    meta: indexkuJMYJoIBAMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-password-reset-key-key",
    path: "/accounts/password/reset/key/:key()",
    meta: _91key_93KyNTDB81c4Meta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/password/reset/key/[key].vue").then(m => m.default || m)
  },
  {
    name: "accounts-password-reset-key-done",
    path: "/accounts/password/reset/key/done",
    meta: doneJ3YrVyF9UQMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/password/reset/key/done.vue").then(m => m.default || m)
  },
  {
    name: "accounts-profile-agents",
    path: "/accounts/profile/agents",
    meta: agentskGXKCGqMWoMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/profile/agents.vue").then(m => m.default || m)
  },
  {
    name: "accounts-profile",
    path: "/accounts/profile",
    meta: index87WCLm4BcNMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "accounts-signup",
    path: "/accounts/signup",
    meta: signupjOBeTBccSrMeta || {},
    component: () => import("/opt/buildhome/repo/pages/accounts/signup.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: faq6z6qNnXVtzMeta || {},
    component: () => import("/opt/buildhome/repo/pages/faq.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-checkout-cartItemId",
    path: "/orders/checkout/:cartItemId()",
    meta: _91cartItemId_93WDMkW6QmoJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/checkout/[cartItemId].vue").then(m => m.default || m)
  },
  {
    name: "orders-checkout",
    path: "/orders/checkout",
    meta: indexMAffu2oHInMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-estimate-confirm",
    path: "/orders/estimate-confirm",
    meta: estimate_45confirmAbqfzRBsTJMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/estimate-confirm.vue").then(m => m.default || m)
  },
  {
    name: "orders-history-orderGroupId",
    path: "/orders/history/:orderGroupId()",
    meta: _91orderGroupId_93RWdXJrhbqyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/history/[orderGroupId].vue").then(m => m.default || m)
  },
  {
    name: "orders-history",
    path: "/orders/history",
    meta: indexlsbnflpvOFMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/history/index.vue").then(m => m.default || m)
  },
  {
    name: "orders-order-confirm-orderGroupId",
    path: "/orders/order-confirm/:orderGroupId()",
    meta: _91orderGroupId_93DUv8FEsdJuMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/order-confirm/[orderGroupId].vue").then(m => m.default || m)
  },
  {
    name: "orders-order-confirm",
    path: "/orders/order-confirm",
    meta: indexRWWTRn8HzHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/orders/order-confirm/index.vue").then(m => m.default || m)
  },
  {
    name: "products-skuId",
    path: "/products/:skuId()",
    meta: _91skuId_93pC0c9HHvXyMeta || {},
    component: () => import("/opt/buildhome/repo/pages/products/[skuId].vue").then(m => m.default || m)
  },
  {
    name: "products-search",
    path: "/products/search",
    meta: search9SBE2lFXzHMeta || {},
    component: () => import("/opt/buildhome/repo/pages/products/search.vue").then(m => m.default || m)
  }
]