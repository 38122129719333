import revive_payload_client_rgDeGvZLCZ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_7bWP8ayK1l from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_NVGHg37wkQ from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_XuWmySZEyT from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_LqkcITTRVo from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ErmoCYAMha from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_lK62uTmDpY from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_mJgApyzTUS from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wSeIJmMcRO from "/opt/buildhome/repo/node_modules/.pnpm/nuxt-graphql-client@0.2.36_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_graphql-t_6ql3qsbaa5glhrvmn2ecaql6fa/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import sentry_3AyO8nEfhE from "/opt/buildhome/repo/plugins/sentry.ts";
import zod_q7cfI0sipZ from "/opt/buildhome/repo/plugins/zod.ts";
import onGqlError_y1vICByWIg from "/opt/buildhome/repo/plugins/onGqlError.ts";
import router_client_W01CLn04zL from "/opt/buildhome/repo/plugins/router.client.ts";
export default [
  revive_payload_client_rgDeGvZLCZ,
  unhead_7bWP8ayK1l,
  router_NVGHg37wkQ,
  payload_client_XuWmySZEyT,
  navigation_repaint_client_LqkcITTRVo,
  check_outdated_build_client_ErmoCYAMha,
  chunk_reload_client_lK62uTmDpY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_mJgApyzTUS,
  plugin_wSeIJmMcRO,
  sentry_3AyO8nEfhE,
  zod_q7cfI0sipZ,
  onGqlError_y1vICByWIg,
  router_client_W01CLn04zL
]