import routerOptions0 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.3.0_encoding@0.1.13_eslint@8.51.0_ioredis@5._gg3p4djohnw4srsk5tacanbggm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/opt/buildhome/repo/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}