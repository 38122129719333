import {
  UserManager,
  InMemoryWebStorage,
  WebStorageStateStore,
} from 'oidc-client-ts';

import type { SigninRedirectArgs, UserManagerSettings } from 'oidc-client-ts';

type AuthState = {
  userManager: UserManager;
  refreshToken: string | null;
};

export const useAuth = () => {
  return new Auth();
};

class Auth {
  _state: Ref<AuthState>;

  constructor() {
    const config = useRuntimeConfig();

    const umConfig = {
      authority: config.public.oidcIssuer,
      client_id: config.public.oidcClientId,
      redirect_uri: `${window.location.origin}/accounts/callback`,
      post_logout_redirect_uri: `${window.location.origin}/accounts/login`,
      // userStore: new WebStorageStateStore({ store: window.localStorage }),
      userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
      response_mode: 'query',
      scope: 'openid',
      authorization_endpoint: `${config.public.oidcIssuer}/o/authorize/`,
      token_endpoint: `${config.public.oidcIssuer}/o/token/`,
      // revokeTokensOnSignout: true, // NOTE: トークンリボークAPIが必要
    } as UserManagerSettings;

    this._state = useState<AuthState>('auth_state', () => ({
      userManager: new UserManager(umConfig),
      refreshToken: null,
    }));

    this.setEventListeners();
    this._state.value.userManager.startSilentRenew();
  }

  setEventListeners() {
    this._state.value.userManager.events.addUserSignedIn(() => {
      // eslint-disable-next-line no-console
      console.log('addUserSignedIn');
    });
  }

  get state() {
    return readonly(this._state);
  }

  login(args?: SigninRedirectArgs) {
    return this._state.value.userManager.signinRedirect(args);
  }

  loginSilent() {
    return this._state.value.userManager.signinSilent();
  }

  async loginCallback() {
    const user = await this._state.value.userManager.signinRedirectCallback();
    useGqlToken({
      // token: user.id_token || user.access_token,
      token: user.access_token,
      config: {
        // type: 'JWT',
        type: 'Bearer',
      },
    });
    this._state.value.refreshToken = user.refresh_token || null;
    return user;
  }

  async logout() {
    await this._state.value.userManager.signoutRedirect();
  }

  async logoutSilent() {
    await this._state.value.userManager.signoutSilent();
  }

  async revokeTokens() {
    await this._state.value.userManager.revokeTokens();
  }

  async isLoggedIn() {
    const user = await this._state.value.userManager.getUser();
    return !!user;
  }
}
