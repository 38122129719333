import { extractRouteAuthMeta, normalizePath } from './auth/utils';

const checkAuth = async (): Promise<boolean> => {
  const auth = useAuth();
  return await auth.isLoggedIn();
};

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const auth = extractRouteAuthMeta(to);
  if (!auth) {
    // No auth required
    // { auth: false }
    return;
  }

  const isGuestPage = auth === 'guest';
  const insidePage = (page: string) =>
    normalizePath(to.path) === normalizePath(page);

  const isLoggedIn = await checkAuth();
  // if (!isLoggedIn) {
  //   try {
  //     // NOTE: クリックジャッキング対策のため、機能しない
  //     const _auth = useAuth();
  //     await _auth.loginSilent();
  //     isLoggedIn = await checkAuth();
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }
  if (isLoggedIn) {
    // -- トークンが有効な場合 --
    if (
      insidePage('/accounts/login') ||
      insidePage('/accounts/callback') ||
      isGuestPage
    ) {
      // ログインページまたはゲストページの場合はトップページへ
      return navigateTo({
        path: '/',
      });
    }
  } else if (!isGuestPage) {
    // -- Guest or トークン無効 --
    return navigateTo({
      name: 'accounts-login',
      query: {
        next: to.fullPath,
      },
    });
  }
});
