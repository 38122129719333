import i18next from 'i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import translation from 'zod-i18n-map/locales/ja/zod.json';

export default defineNuxtPlugin(() => {
  i18next.init({
    lng: 'ja',
    resources: {
      ja: { zod: translation },
    },
  });
  z.setErrorMap(zodI18nMap);
});
