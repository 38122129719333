import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["AddCart","RemoveCartItem","UpdateCartItem","GetCartItem","GetCartItems","GetCities","GetConfigs","EmailVerify","GetEmailVerify","RequestEstimate","CheckEstimatePdfGenerated","GetEstimateFileUrl","GetGrade","GetGrades","GetOrders","ObtainOrderIdByGroupOrderId","GetLastUpdatedOrderDetail","OrderByCartItems","GetOrderGroup","GetOrderGroups","DiscardOrder","OrderFromEstimate","GetOrderGroupsCount","GetPrefectures","GetProfile","UpdateProfile","GetPublisher","GetPublishers","GetQAndAs","ForcePasswordReset","RequestPasswordReset","PasswordReset","GetProductReviewStatus","GetReview","GetReviews","ReviewCreate","GetSalesAgentsFilteredSchool","GetSalesAgent","GetSchools","GetSchool","GetSchoolSubjects","Signup","GetSku","GetProductReviews","GetLatestSku","SearchSku","GetSubjectAreas","GetSubjectArea","GetSubjects","GetSubject","GetTerm","GetTerms","TradeRequest","TradeRequestCancel","GetTeacherAgents","GetUsingPurpose","GetUsingPurposes"]}
export const GqlAddCart = (...params) => useGql()('AddCart', ...params)
export const GqlCheckEstimatePdfGenerated = (...params) => useGql()('CheckEstimatePdfGenerated', ...params)
export const GqlDiscardOrder = (...params) => useGql()('DiscardOrder', ...params)
export const GqlEmailVerify = (...params) => useGql()('EmailVerify', ...params)
export const GqlForcePasswordReset = (...params) => useGql()('ForcePasswordReset', ...params)
export const GqlGetCartItem = (...params) => useGql()('GetCartItem', ...params)
export const GqlGetCartItems = (...params) => useGql()('GetCartItems', ...params)
export const GqlGetCities = (...params) => useGql()('GetCities', ...params)
export const GqlGetConfigs = (...params) => useGql()('GetConfigs', ...params)
export const GqlGetEmailVerify = (...params) => useGql()('GetEmailVerify', ...params)
export const GqlGetEstimateFileUrl = (...params) => useGql()('GetEstimateFileUrl', ...params)
export const GqlGetGrade = (...params) => useGql()('GetGrade', ...params)
export const GqlGetGrades = (...params) => useGql()('GetGrades', ...params)
export const GqlGetLastUpdatedOrderDetail = (...params) => useGql()('GetLastUpdatedOrderDetail', ...params)
export const GqlGetLatestSku = (...params) => useGql()('GetLatestSku', ...params)
export const GqlGetOrderGroup = (...params) => useGql()('GetOrderGroup', ...params)
export const GqlGetOrderGroups = (...params) => useGql()('GetOrderGroups', ...params)
export const GqlGetOrderGroupsCount = (...params) => useGql()('GetOrderGroupsCount', ...params)
export const GqlGetOrders = (...params) => useGql()('GetOrders', ...params)
export const GqlGetPrefectures = (...params) => useGql()('GetPrefectures', ...params)
export const GqlGetProductReviewStatus = (...params) => useGql()('GetProductReviewStatus', ...params)
export const GqlGetProductReviews = (...params) => useGql()('GetProductReviews', ...params)
export const GqlGetProfile = (...params) => useGql()('GetProfile', ...params)
export const GqlGetPublisher = (...params) => useGql()('GetPublisher', ...params)
export const GqlGetPublishers = (...params) => useGql()('GetPublishers', ...params)
export const GqlGetQAndAs = (...params) => useGql()('GetQAndAs', ...params)
export const GqlGetReview = (...params) => useGql()('GetReview', ...params)
export const GqlGetReviews = (...params) => useGql()('GetReviews', ...params)
export const GqlGetSalesAgent = (...params) => useGql()('GetSalesAgent', ...params)
export const GqlGetSalesAgentsFilteredSchool = (...params) => useGql()('GetSalesAgentsFilteredSchool', ...params)
export const GqlGetSchool = (...params) => useGql()('GetSchool', ...params)
export const GqlGetSchoolSubjects = (...params) => useGql()('GetSchoolSubjects', ...params)
export const GqlGetSchools = (...params) => useGql()('GetSchools', ...params)
export const GqlGetSku = (...params) => useGql()('GetSku', ...params)
export const GqlGetSubject = (...params) => useGql()('GetSubject', ...params)
export const GqlGetSubjectArea = (...params) => useGql()('GetSubjectArea', ...params)
export const GqlGetSubjectAreas = (...params) => useGql()('GetSubjectAreas', ...params)
export const GqlGetSubjects = (...params) => useGql()('GetSubjects', ...params)
export const GqlGetTeacherAgents = (...params) => useGql()('GetTeacherAgents', ...params)
export const GqlGetTerm = (...params) => useGql()('GetTerm', ...params)
export const GqlGetTerms = (...params) => useGql()('GetTerms', ...params)
export const GqlGetUsingPurpose = (...params) => useGql()('GetUsingPurpose', ...params)
export const GqlGetUsingPurposes = (...params) => useGql()('GetUsingPurposes', ...params)
export const GqlObtainOrderIdByGroupOrderId = (...params) => useGql()('ObtainOrderIdByGroupOrderId', ...params)
export const GqlOrderByCartItems = (...params) => useGql()('OrderByCartItems', ...params)
export const GqlOrderFromEstimate = (...params) => useGql()('OrderFromEstimate', ...params)
export const GqlPasswordReset = (...params) => useGql()('PasswordReset', ...params)
export const GqlRemoveCartItem = (...params) => useGql()('RemoveCartItem', ...params)
export const GqlRequestEstimate = (...params) => useGql()('RequestEstimate', ...params)
export const GqlRequestPasswordReset = (...params) => useGql()('RequestPasswordReset', ...params)
export const GqlReviewCreate = (...params) => useGql()('ReviewCreate', ...params)
export const GqlSearchSku = (...params) => useGql()('SearchSku', ...params)
export const GqlSignup = (...params) => useGql()('Signup', ...params)
export const GqlTradeRequest = (...params) => useGql()('TradeRequest', ...params)
export const GqlTradeRequestCancel = (...params) => useGql()('TradeRequestCancel', ...params)
export const GqlUpdateCartItem = (...params) => useGql()('UpdateCartItem', ...params)
export const GqlUpdateProfile = (...params) => useGql()('UpdateProfile', ...params)