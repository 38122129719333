import type { RouterConfig } from '@nuxt/schema';
import type { RouterScrollBehavior, RouteLocationNormalized } from 'vue-router';
import { appPageTransition as defaultPageTransition } from '#build/nuxt.config.mjs';

const findHashPosition = (hash: string): ReturnType<RouterScrollBehavior> => {
  const selector = `#${CSS.escape(decodeURI(hash.slice(1)))}`;
  const el = document.querySelector(selector);

  // vue-router does not incorporate scroll-margin-top on its own.
  if (el) {
    const top = Number.parseFloat(getComputedStyle(el).scrollMarginTop);
    return {
      el: selector,
      behavior: 'smooth',
      left: 0,
      top,
    };
  }
  return false;
};

export default <RouterConfig>{
  scrollBehavior(to, from, savedPosition) {
    const nuxtApp = useNuxtApp();

    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return new Promise((resolve) => {
        if (to.path === from.path) {
          setTimeout(() => resolve(findHashPosition(to.hash)), 50);
        } else {
          const hasTransition = (route: RouteLocationNormalized) =>
            !!(route.meta.pageTransition ?? defaultPageTransition);
          const hookToWait =
            hasTransition(from) && hasTransition(to)
              ? 'page:transition:finish'
              : 'page:finish';
          nuxtApp.hooks.hookOnce(hookToWait, () => {
            setTimeout(() => resolve(findHashPosition(to.hash)), 1000);
          });
        }
      });
    }

    return { x: 0, y: 0 };
  },
};
